//
// slimscroll.scss
//

@import '~simplebar/dist/simplebar.min.css';

.simplebar-scrollbar {
    /* Removed width because of problems for implementation without slimscroll */
    /* width: 4px !important; */

    &:before {
        background: rgb(158, 165, 171);
        height: 100%;
        width: 100%;
    }
}